import ReactDOM from "react-dom/client";
import * as React from "react"
import type { GatsbyBrowser } from "gatsby"

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}


// Replace this by your own page wrapper:
// exports.wrapPageElement = ({ element, props }) => {
//   return <Layout {...props}>{element}</Layout>
// }

// export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
//   element,
// }) => {
//   return (
//     <div>
//       <h1>Hello World</h1>
//       {element}
//     </div>
//   )
// }